@use '../../styles/colors' as colors;

span.about-step {
    /* position */
    gap: 12px;
    display: flex;
    flex-direction: column;

    /* size */
    width: 100%;
    padding: 15px 10px;

    /* styles for texts */
    font-size: 14px;
    font-weight: 600;
    text-align: center;

    /* styling */
    user-select: none;
    border-radius: 10px;
    color: colors.$primary;
    background-color: colors.$primary_light;
}