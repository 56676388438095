// Common colors
$primary: #65733b;
$primary_hover: #505d28;
$primary_light: #eef1e5;

// Colors for text
$primary_text: #22303e;
$primary_text_label: #505c2b;
$primary_text_field: #292929;
$primary_border_field: #CED1D5;
$primary_border_field_hover: #505c2b;

$primary_light_background: #F5F5F5;

$error: #F04438;
$error-hover: #bc1105;













$light-button: #fad3e989;

$text-main-button: #FFF;
$text-light-button: #5c2542;

$main-text: #384551;
$secondary-text: #949BA2;

//
$primary-color: #5c2542;


$button-light-background: #F5F5F5;
$button-light-background-hover: #ededed;

//
$white: #FFF;
$black: #42464d;






//
$primary-color-hover: #4b0c2e;

//
$background-primary-color: #F5F5F9;

//
$white-color: #FFF;
$black-color: #42464d;

//
$success-color: #11a917;
$error: #F04438;
$warning-color: #FFB200;
$info-color: #155BCB;

//
$error-hover: #bc1105;

//
$default-icon-color: #adadad;

// Navbar colors
$navbar-title: rgba(34, 48, 62, 0.5);
$navbar-primary-color: rgba(34, 48, 62, 0.9);
$navbar-primary-color-hover: #696CFF;
$navbar-menu-color-hover: #E7E7FF;
$navbar-submenu-color-hover: #EDEEF0;

//
$primary-color-for-text-field: #292929;
$primary-color-for-border-field: #CED1D5;
$primary-color-for-border-field-hover: rgb(92, 37, 66, 0.8);

//
$button-primary-color: #696CFF;
$button-primary-color-hover: #5c5efd;
$button-text-primary-color: #FFF;
$button-text-primary-color-hover: #FFF;

