@use '../colors' as colors;

// Defines the variables
$global-gap: 15px;
$app-logo-height: 65px;

// Defines app paddings
$app-top-padding: 40px;
$app-sides-padding: 20px;
$app-bottom-padding: 30px;

// Defines the calcs
$app-calc-desktop: 150px;
$app-calc-mobile: 120px;

// Mixin for the swing animation
@keyframes shake {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(var(--shake-intensity));
    }
    50% {
        transform: translateX(calc(var(--shake-intensity) * -1));
    }
    75% {
        transform: translateX(var(--shake-intensity));
    }
    100% {
        transform: translateX(0);
    }
}

@mixin shake-animation($duration: 0.5s, $intensity: 10px) {
    --shake-intensity: #{$intensity};
    animation: shake #{$duration} ease-in-out;
}

body {
    background-color: #f2f4f7;
}

section#app-scheduling {
    /* size */
    width: 450px;
    height: auto;
    min-height: 100dvh;
    padding: $app-top-padding $app-sides-padding $app-bottom-padding $app-sides-padding;

    /* position */
    margin: auto;

    /* styling */
    background-color: colors.$white;
    box-shadow: 0px 0px 72px -29px rgba(0, 0, 0, 0.10);

    /* responsive */
    @media (max-width: 450px) {
        width: 100vw;
        padding: calc($app-top-padding / 2) calc($app-sides-padding / 2) $app-bottom-padding calc($app-sides-padding / 2);

    }

    & section.content {
        /* position */
        display: flex;
        gap: $global-gap;
        flex-direction: column;

        & .logo {
            /* size */
            width: 40px;
            height: $app-logo-height;

            /* position */
            margin: auto;

            /* styling */
            flex-shrink: 0;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url('../../images/icon.png');
        }

        & section.step-content {
            /* size */
            width: 100%;
            min-height: calc(100dvh - ($app-calc-desktop));

            /* position */
            display: flex;
            gap: $global-gap;
            flex-direction: column;
            justify-content: space-between;

            /* responsive */
            @media (max-width: 450px) {
                min-height: calc(100dvh - ($app-calc-mobile));
            }

            & div.step-body {
                display: flex;
                gap: $global-gap;
                flex-direction: column;

                & .options-for-progress {
                    display: flex;

                    &.row {
                        gap: 4%;
                        flex-direction: row;

                        & button.btn {
                            width: 48%;
                        }
                    }

                    &.column {
                        gap: 10px;
                        flex-direction: column;

                        & button.btn {
                            width: 100%;
                        }
                    }

                    & button.btn {
                        /* size */
                        padding: 15px;

                        /* position */
                        gap: 10px;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;

                        /* styling */
                        font-size: 16px;
                        font-weight: 500;
                        border-radius: 10px;
                        transition: all 0.3s ease-in-out;

                        &.btn-default {
                            /* styling */
                            color: colors.$primary;
                            background-color: colors.$button-light-background;

                            & .styled-icon {
                                fill: colors.$primary;
                                transition: all 0.3s ease-in-out;
                            }

                            & p {
                                /* position */
                                margin-top: -8px;

                                /* styling */
                                font-size: 12px;
                                font-weight: 400;
                            }

                            &:hover,
                            &.selected {
                                color: colors.$white;
                                background-color: colors.$primary;

                                & .styled-icon {
                                    fill: colors.$white;
                                }
                            }
                        }
                    }
                }

                & .custom-service-option {
                    display: flex;
                    align-items: center;

                    & .option-body {
                        width: 100%;

                        & .custom-option {
                            /* size */
                            width: 100%;
                            height: 140px;
                            padding: 12px;

                            /* position */
                            gap: 10px;
                            display: flex;

                            /* styling */
                            cursor: pointer;
                            border-radius: 8px;
                            transition: 0.2s all ease-in-out;
                            background-color: colors.$button-light-background;
                            box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.08);

                            &.new {
                                padding: 12px 12px 15px 12px;
                                box-shadow: inset 0px -3px 0px colors.$primary-color;
                            }

                            &.promo {
                                padding: 12px 12px 15px 12px;
                                box-shadow: inset 0px -3px 0px #EAB308;
                            }

                            &:hover {
                                @include shake-animation(0.4s, 3px);
                            }

                            & .option-image {
                                width: 90px;
                                height: 110px;

                                & img {
                                    /* size */
                                    width: 90px;
                                    height: 100%;
                                    flex-shrink: 0;

                                    /* styling */
                                    object-fit: cover;
                                    border-radius: 5px;
                                }
                            }

                            & .option-data {
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;

                                & .option-header {
                                    /* position */
                                    gap: 2px;
                                    display: flex;
                                    flex-direction: column;

                                    /* styling */
                                    color: colors.$black;

                                    & .option-title {
                                        font-weight: 600;
                                        font-size: 1rem;
                                    }

                                    & .option-description {
                                        font-weight: 400;
                                        font-size: 0.7rem;
                                    }
                                }

                                & .option-footer {
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-between;

                                    & .option-tag {
                                        /* size */
                                        display: flex;
                                        padding: 4px 8px;

                                        /* position */
                                        gap: 2px;
                                        align-items: center;
                                        flex-direction: row;
                                        justify-content: center;

                                        /* styling */
                                        font-size: 0.7rem;
                                        border-radius: 4px;
                                        color: colors.$white;

                                        @media (max-width: 350px) {
                                            font-size: 0.6rem;
                                        }

                                        &.new {
                                            background-color: colors.$primary-color;
                                        }

                                        &.promo {
                                            background-color: #EAB308;
                                            box-shadow: inset 0px -3px 0px #EAB308;
                                        }

                                        & .styled-icon {
                                            fill: colors.$white;
                                        }
                                    }

                                    & .option-price {
                                        font-weight: 600;
                                        font-size: 1.2rem;
                                        color: colors.$black;

                                        @media (max-width: 350px) {
                                            font-size: 1.1rem;
                                        }
                                    }
                                }
                            }
                        }

                        & .custom-checkbox {
                            display: none;

                            &:checked+.custom-option {
                                padding: 12px 12px 15px 12px;
                                background-color: #caffe34d;
                                box-shadow: inset 0px -3px 0px #11A917;

                                .option-image.selected {
                                    /* position */
                                    display: flex;
                                    position: relative;
                                    justify-content: center;

                                    /* styling */
                                    flex-shrink: 0;

                                    & img {
                                        /* size */
                                        width: 100%;
                                        height: auto;

                                        /* position */
                                        display: block;
                                    }

                                    & .option-image-selected {
                                        /* size */
                                        width: 100%;
                                        height: 100%;

                                        /* position */
                                        display: flex;
                                        position: absolute;
                                        align-items: center;
                                        justify-content: center;

                                        /* styling */
                                        pointer-events: none;
                                        background-color: #f0fff7b8;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            & div.step-footer {
                & .step-buttons {
                    gap: 10px;
                    display: flex;
                    flex-direction: column;

                    & button.btn {
                        /* size */
                        width: 100%;

                        /* position */
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        /* styling */
                        border-radius: 10px;
                        text-transform: uppercase;
                        transition: background 0.4s ease-in-out;

                        &.btn-default {
                            /* size */
                            height: 50px;

                            /* styling */
                            font-size: 14px;
                            color: colors.$white-color;
                            background-color: colors.$primary;

                            &:hover {
                                /* styling */
                                background-color: colors.$primary_hover;
                            }
                        }

                        &.btn-small-default {
                            /* size */
                            height: 40px;

                            /* styling */
                            font-size: 12px;
                            font-weight: 600;
                            color: colors.$primary;
                            background-color: colors.$button-light-background;

                            &:hover {
                                /* styling */
                                background-color: colors.$button-light-background-hover;
                            }
                        }
                    }

                    & .btn-link {
                        /* position */
                        display: flex;

                        /* styling */
                        font-size: 12px;
                        font-weight: 500;
                        text-decoration: none;
                        color: colors.$primary-color;
                        transition: color 0.4s ease-in-out;
                    }
                }
            }
        }

        & form.scheduling {
            /* position */
            gap: 30px;
            display: flex;
            flex-direction: column;

            & .scheduling-fields {
                /* position */
                gap: 15px;
                display: flex;
                flex-direction: column;

                & div.custom-selects {
                    /* size */
                    gap: 5px;
                    width: 100%;
                    height: auto;

                    /* position */
                    display: flex;
                    flex-direction: column;

                    & div.custom-select-option {
                        /* size */
                        width: 100%;
                        height: 150px;
                        display: flex;
                        flex-direction: row;

                        /* styling */
                        user-select: none;
                        border-radius: 10px;

                        & .service-image {
                            /* size */
                            width: 150px;
                            height: 150px;
                            flex-shrink: 0;

                            /* styling */
                            border-radius: 10px 0 0 10px;
                            background-size: cover;
                            background-repeat: no-repeat;
                            pointer-events: none;
                        }

                        & .serivce-content {
                            /* size */
                            width: 100%;
                            height: 100%;
                            padding: 10px;

                            /* position */
                            gap: 5px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            & .service-body {
                                gap: 3px;
                                display: flex;
                                flex-direction: column;

                                & .serivce-header {
                                    /* size */
                                    width: 100%;
                                    display: flex;

                                    /* position */
                                    flex-direction: row;
                                    justify-content: space-between;

                                    & .service-type {
                                        /* styles for texts */
                                        font-size: 20px;
                                        font-weight: 600;

                                        /* styling */
                                        color: colors.$main-text;
                                    }
                                }

                                & .service-description {
                                    /* styles for texts */
                                    font-size: 14px;
                                    font-weight: 400;

                                    /* styling */
                                    color: colors.$secondary-text;
                                }
                            }

                            & .service-footer {
                                text-align: end;

                                & .service-price {
                                    /* styles for texts */
                                    font-size: 24px;
                                    font-weight: 600;

                                    /* styling */
                                    color: colors.$main-text;
                                }
                            }
                        }
                    }
                }

                & .times-options {
                    gap: 10px;
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));

                    @media (max-width: 329px) {
                        gap: 8px;
                        grid-template-columns: repeat(auto-fit, minmax(65px, 1fr));
                    }
                }

                &.payments {
                    flex-direction: row;
                }

                & .search-date-of-service {
                    /* size */
                    gap: 10px;

                    /* position */
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    @media (max-width: 370px) {
                        flex-direction: column;
                    }

                    & .field-date-of-service {
                        gap: 8px;
                        display: flex;
                        flex-direction: column;

                        & label {
                            font-size: 14px;
                            font-weight: 400;
                            color: colors.$primary_text;
                        }

                        & .input-with-icon {
                            /* position */
                            display: flex;
                            position: relative;
                            align-items: center;

                            /* styling */
                            cursor: pointer;
                            font-size: 14px;
                            border-radius: 4px;
                            border: 1px solid #CED1D5;

                            .react-datepicker__current-month {
                                &::first-letter {
                                    text-transform: uppercase;
                                }
                            }

                            .react-datepicker-popper {
                                width: 100%;

                                .react-datepicker {
                                    /* size */
                                    padding: 15px;

                                    /* styling */
                                    border: none;
                                    background-color: colors.$white;
                                    -webkit-box-shadow: 0px 0px 9px 6px rgba(0, 0, 0, 0.05);
                                    -moz-box-shadow: 0px 0px 9px 6px rgba(0, 0, 0, 0.05);
                                    box-shadow: 0px 0px 9px 6px rgba(0, 0, 0, 0.05);

                                    .react-datepicker__header {
                                        border: none;
                                        background-color: colors.$white;

                                        .react-datepicker__current-month {
                                            color: colors.$primary_text;
                                        }

                                        .react-datepicker__day-name {
                                            font-size: 10px;
                                            font-weight: 500;
                                            color: #9AA3B1;
                                            text-transform: uppercase;
                                        }
                                    }

                                    .react-datepicker__navigation {
                                        top: auto;
                                    }

                                    .react-datepicker__navigation--next {
                                        right: 15px;
                                    }

                                    .react-datepicker__navigation--previous {
                                        left: 15px;
                                    }

                                    .react-datepicker__day {
                                        color: colors.$primary_text;
                                    }

                                    .react-datepicker__day--disabled,
                                    .react-datepicker__day--outside-month {
                                        color: #ccc;
                                    }

                                    .react-datepicker__day--selected {
                                        font-weight: 700;
                                        border-radius: 0;
                                        color: colors.$primary;
                                        background-color: colors.$white;
                                        border-bottom: 2px solid colors.$primary;
                                    }

                                    .react-datepicker__day--keyboard-selected {
                                        background-color: colors.$white;
                                    }
                                }

                                .react-datepicker__triangle {
                                    display: none;
                                }
                            }

                            input.custom-input-date-of-service {
                                /* size */
                                width: 100%;
                                height: 40px;
                                padding: 12px;
                                min-width: 210px;
                                padding: 10px 40px 10px 10px;

                                /* position */
                                flex-grow: 0 !important;

                                /* styling */
                                cursor: pointer;
                                font-size: 14px;
                                border-radius: 4px;
                                color: colors.$primary_text;

                                @media (max-width: 420px) {
                                    min-width: 180px;
                                }

                                @media (max-width: 390px) {
                                    min-width: 160px;
                                }
                            }

                            & svg.styled-svg {
                                /* position */
                                top: 50%;
                                right: 10px;
                                position: absolute;

                                /* styling */
                                pointer-events: none;
                                fill: colors.$primary_text;
                                transform: translateY(-50%);
                            }
                        }
                    }

                    & button.custom-button {
                        min-width: 180px;

                        @media (max-width: 370px) {
                            width: 100%;
                            margin-top: 0;
                            padding: 5px 20px;
                        }
                    }
                }

                & button.custom-button {
                    /* size */
                    width: 250px;
                    height: 40px;
                    padding: 5px 25px;

                    /* position */
                    margin-top: 25px;

                    /* styling */
                    font-size: 12px;
                    font-weight: 500;
                    border-radius: 8px;
                    color: colors.$white;
                    background-color: colors.$primary;
                    transition: 0.2s all ease-in-out;

                    &:hover {
                        background-color: colors.$primary-hover;
                    }

                    & .loading {
                        /* size */
                        width: 20px;
                        height: 20px;

                        /* position */
                        display: inline-block;

                        /* styling */
                        border-radius: 50%;
                        border-top-color: #FFF !important;
                        border: 3px solid rgba(0, 0, 0, 0.1);
                        animation: spin 0.8s ease-in-out infinite;

                        @keyframes spin {
                            to {
                                transform: rotate(360deg);
                            }
                        }
                    }
                }

                & .custom-radio {
                    display: flex;
                    align-items: center;

                    & .custom-check {
                        /* size */
                        width: 67.7px;
                        padding: 8px 0;

                        /* position */
                        display: flex;
                        text-align: center;
                        justify-content: center;

                        /* styling */
                        cursor: pointer;
                        font-size: 14px;
                        font-weight: 600;
                        border-radius: 8px;
                        background-color: #EDEEF0;
                        transition: 0.2s all ease-in-out;

                        &:hover {
                            color: colors.$primary;
                            background-color: colors.$primary_light;
                        }
                    }

                    & .custom-input-radio {
                        display: none;

                        &:checked+.custom-check {
                            color: colors.$primary;
                            background-color: colors.$primary_light;
                        }
                    }
                }

                & .selected-info {
                    /* position */
                    display: flex;
                    padding: 10px 0;
                    justify-content: center;

                    /* styling */
                    font-size: 14px;
                    font-weight: 500;
                    border-radius: 5px;
                    color: colors.$primary_text;
                    background-color: colors.$primary_light_background;
                }

                & button.payment-method {
                    gap: 5px;
                    width: 50%;
                    display: flex;
                    padding: 20px 10px;
                    font-size: 14px;
                    font-weight: 500;
                    border-radius: 8px;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    color: colors.$black;
                    transition: 0.2s all ease-in-out;

                    @media (max-width: 520px) {
                        flex-direction: column;
                    }

                    &:hover {
                        color: #696CFF;
                        background-color: #E7E7FF;

                        & .styled-icon {
                            fill: #696CFF;
                        }
                    }

                    & .styled-icon {
                        fill: colors.$black;
                        transition: 0.2s all ease-in-out;
                    }
                }

                & .feedback-times-options {
                    /* size */
                    padding: 14px;

                    /* position */
                    gap: 10px;
                    display: flex;
                    align-items: center;

                    /* styling */
                    font-size: 12px;
                    font-weight: 500;
                    border-radius: 8px;
                    color: colors.$error;
                    background-color: rgb(255, 235, 235);

                    & .icon {
                        flex-shrink: 0 !important;
                        stroke: colors.$error;
                    }
                }
            }
        }
    }
}